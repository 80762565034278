<template>
  <ion-page class="page position-fixed d-flex align-items-center justify-content-center">
    <div class="text-white text-center">
      <img loading="lazy" src="/logo-circle-white.png" width="100px" height="100px" />
      <div class="title mt-3">Oops, this is an error page</div>
      <div class="subtitle">We'll get on this issue!</div>
      <router-link :to="{ name: 'home' }">
        <ion-button fill="clear" color="light" class="mt-3"> Back to home </ion-button>
      </router-link>
    </div>
  </ion-page>
</template>

<script lang="ts" setup></script>
<style lang="sass" scoped>
.page
  width: 100vw
  height: 100vh
  z-index: 1111111
  background: #ae38e5
  img
    width: 100px
    height: 100px
  .title
    font-size: 25px
    font-weight: bold
  .subtitle
    font-size: 14px
</style>
